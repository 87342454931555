.rg-celleditor-input,
.rg-celleditor input {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 1em;
  outline: none;
  border: white;
}
.collapsed {
  transform: rotate(90deg);
}

.rg-header-cell {
  background-color: #F9F9FC !important;
  color: #3A3549 !important;
}

.rg-cell {
  border-color: #e0e0e0;
}

.rg-action-cell {
  padding-left: 15px !important;
}

.rg-pane-left {
  z-index: 2;
}

.spreadsheet-grid-container {
  border: 1px solid #cdcdd6;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.spreadsheet-grid-container:hover {
  box-shadow: rgba(130, 133, 134, 0.15) 0px 1px 2px 0px,
    rgba(130, 133, 134, 0.15) 0px 2px 6px 6px;
  cursor: pointer;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textfield-white {
  background-color: #ffffff;
}

.text-transform {
  text-transform: capitalize;
}

.selection-tile {
  display: grid;
  justify-items: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  width: 120px;
}

.rg-fill-handle,
.rg-touch-fill-handle {
  height: 0px !important;
  width: 0px !important;
  display: none !important;
}

.rg-pane-top {
  z-index: 3;
}

.rg-pane-left.rg-pane-top {
  z-index: 10;
}

@media (min-width: 600px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 85vw;
    overflow: auto;
  }
}
@media (min-width: 800px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 87vw;
    overflow: auto;
  }
}
@media (min-width: 900px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 87.75vw;
    overflow: auto;
  }
}
@media (min-width: 1000px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 88.5vw;
    overflow: auto;
  }
}
@media (min-width: 1100px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 90vw;
    overflow: auto;
  }
}
@media (min-width: 1200px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 90.5vw;
    overflow: auto;
  }
}
@media (min-width: 1300px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 91.5vw;
    overflow: auto;
  }
}
@media (min-width: 1400px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 92vw;
    overflow: auto;
  }
}
@media (min-width: 1500px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 92.5vw;
    overflow: auto;
  }
}
@media (min-width: 1600px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 93vw;
    overflow: auto;
  }
}
@media (min-width: 1700px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 93.25vw;
    overflow: auto;
  }
}
@media (min-width: 1800px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 93.5vw;
    overflow: auto;
  }
}
@media (min-width: 1900px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 93.75vw;
    overflow: auto;
  }
}
@media (min-width: 2000px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 94vw;
    overflow: auto;
  }
}
@media (min-width: 2100px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 94.25vw;
    overflow: auto;
  }
}

@media (min-width: 2200px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 94.5vw;
    overflow: auto;
  }
}
@media (min-width: 2300px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 95vw;
    overflow: auto;
  }
}
@media (min-width: 2400px) {
  .spreadsheet-container {
    max-height: 70vh;
    max-width: 95.5vw;
    min-width: 600px;
    overflow: auto;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.account-metric-div {
  padding: 10px;
  border: 2px black solid;
  border-radius: 10px;
}

.center-div-message {
  margin: auto;
  width: 60%;
}

.total-row {
  font-weight: 700;
  font-size: 16px;
}
.custom-metric-row {
  font-weight: 700;
  font-size: 14px;
  background-color: #dfe4f0 !important;
}
.account-group-row-open {
  font-weight: 700;
  font-size: 14px;
  background-color: #eff2f8;
}
.account-group-row-close {
  font-weight: 500;
  font-size: 14px;
  background-color: #eff2f8;
}
.account-row-open {
  font-weight: 700;
  font-size: 14px;
  background-color: #ffffff;
}
.account-row-close {
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
}
.metric-row-input {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #094498;
}
.metric-row-other {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}
.rg-cell-nonEditable {
  background-color: #ffffff;
}
.text-italics {
  font-style: italic;
}
