.App {
  text-align: center;

}
body{  font-family: 'Inter !important', sans-serif;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.m-0{
  margin: 0px !important;
}

.m-5 {
  margin: 5px;
}

.p-10{
  padding: 10px;
}

.p-20{
  padding: 20px;
}
.p-24{
  padding: 24px;
}
.p-x-24{
  padding-top: 24px;
  padding-bottom: 24px;
}
.p-t-20{
  padding-top: 20px;
}


.p-20-horizontal{
  padding-left: 20px;
  padding-right: 20px;
}
.pl-20{
  padding-left: 20px;
}

.ml-10{
  margin-left: 10px !important;
}
.ml-15{
  margin-left: 15px !important;
}


.ml-20{
  margin-left: 20px !important;
}

.ml-30{
  margin-left: 30px !important;
}

.font-bold{
  font-weight: 700 !important;
}

.font-label{
  font-size: 0.95rem !important;
  margin-bottom: 5px;
}
.font-label-small{
  font-size: 0.8rem !important;
  margin-bottom: 5px;
}

.mt-2-5{
  margin-top: 2.5px !important;
}

.mt-5{
  margin-top: 5px !important;
}

.mt-10{
  margin-top: 10px !important;
}

.mt-neg-20{
  margin-top: -20px !important;
}
.mt-neg-7-5{
  margin-top: -7.5px !important;
}
.mt-neg-5{
  margin-top: -5px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}

.mb-25{
  margin-bottom: 25px !important
}

.mt-5{
  margin-top: 5px !important;
}
.mt-15{
  margin-top: 15px !important;
}

.mt-20{
  margin-top: 20px !important;
}

.mt-25{
  margin-top: 25px !important;
}

.mt-30{
  margin-top: 30px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pointer {
  cursor: pointer;
}

.pointer:hover{
  color: cornflowerblue;
  text-decoration: underline;
}
.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-prior-period {
  flex-direction: row-reverse !important;
}

.datepicker-full {
  width: 100%;
}

.button-bottom {
  position: absolute;
  bottom: 0;
  padding: 20px;
  right: 0px;
}

.text-normal {
  text-transform: none !important;
}

.f-16{
font-size:16px
}

.header-color {
  color: #2D2D2D;
}
.full-container{
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 0px 5px 1px grey; */
  border: 1px solid #E0E0E0
}
.nestable-border{
  border-radius: 6px;
  border: 2px solid #E2E8F0;
}

.nestable-list{
  padding: 0px !important;
}

